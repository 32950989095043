
const Certification = ({logo, certificate, title, link}) => {
    return (
        <div className="flex justify-center -center mx-auto! h-100 xl:h-fit! xl:mt-[1.5rem]! w-100 row">
            <img src={certificate} className="max-[376px]:h-[16rem] h-[18rem] px-0 w-auto! md:h-[28rem] xl:mr-[5rem] xl:h-[31rem]! col-xl-6" alt=''/>
            <h1 className="flex flex-column text-center max-[376px]:mt-1! max-[376px]:text-[18px]! mt-4 col-6 col-xl-6 items-center w-fit! mx-auto xl:mx-0! md:ms-5 md:mt-5 h-fit text-black">
                <img src={logo} className="max-[376px]:w-[2rem]! w-[4rem] xl:w-[6rem]! md:w-20 mb-3" alt='' /> 
                {title} 
                <a className="p-2 px-3 hover:scale-[1.05] bg-[#a18276] mt-2 md:mt-4 text-white max-[376px]:text-[12px] text-[20px] rounded-xl fira-sans-semibold" href={link}>LINK</a>
            </h1>
        </div>
    )
}

export default Certification; 