import { useEffect, useState } from "react";
import Project from './Project'
import icons from "../../../assets/icons";

const Projects = ({projects}) => {
    const [currentProject, setCurrentProject] = useState(projects[0])
    useEffect(()=>{
        setCurrentProject(projects[0])
    }, [projects])
    return (
        <>
            <span className="flex border-t-2 border-[#fcdfa6] max-[376px]:pt-[1rem] pt-[2.5rem] max-[376px]:mb-0 mb-[2rem] md:border-0 justify-center">
                <h3 className="d-flex align-items-center max-[376px]:text-[20px]!" style={{width: 'fit-content'}}><p className="me-3 me-xl-4 p-2 px-3  headingDecorationOne fs-4 fs-lg-heading fs-xl-heading rounded">{`<>`}</p>PROJECTS <p className="p-2 fs-lg-heading fs-xl-heading  headingDecorationOne fs-4 fs-md-6 rounded ms-3 ms-xl-4">{`</>`}</p></h3>
            </span>
            <div className="px-0 xl:mt-17 flex justify-center">
                <Project title={currentProject?.title} github={currentProject?.github} stack={currentProject?.stack} type={currentProject?.type} src={currentProject?.src[0]} url={currentProject?.url} logo={currentProject?.logo}/>
            </div>
            <nav className='flex items-center justify-center flex-column flex-md-row xl:mt-[0rem]!  mb-[2.5rem] md:mb-[5.5rem]'>
                <div className="d-flex align-items-center w-55 justify-content-center justify-content-md-end border-md-end w-25 border-bottom border-md-bottom pe-md-4 me-md-4 pb-3 pb-md-0 mb-3 mb-md-0 pe-xl-5 me-xl-5 fa-bounce delay-1">
                    <img src={icons.SelectProjectsIcon} alt=""  className="me-2 projectNavIcon w-[2.5rem] md:w-[4rem]"/>
                    <h6 className="fira-sans-thin-italic-bold mb-0 ml-3">Select Project</h6>
                </div>
                <ul className='d-flex align-items-center flex-wrap justify-content-evenly justify-content-md-start w-75'>
                    {projects.map( project => (
                        <button key={project.$id} className={`${currentProject?.title === project?.title ? 'onFocus':null} max-[376px]:w-[2.5rem]! md:w-[4rem]! xl:w-[5.5rem]! hover:scale-[1.05] border p-1 rounded justify-content-center bg-white m-1 mb-2 btn-sm btn-md btn-lg btn-xl fa-flip`} onClick={() => setCurrentProject(project)}><img src={project.logo} alt="" className="w-100" /></button>
                    ))}
                </ul>
            </nav>
        </>
    )
}

export default Projects;