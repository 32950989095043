import { useState } from "react";
import Mobile from "./Mobile";
import icons from "../../../assets/icons";

const Project = ({src, title, type, stack, url, logo, github}) => {
    const [demo, setDemo] = useState(false)
    const [loaded, setLoaded] = useState(false)
    setTimeout(() => {
        setLoaded(true);
    }, 850);
    return (
        <>
            <div className={`${loaded ? null:'d-none'} w-100 px-0 justify-center`}>
                <div className="md:hidden max-[376px]:mb-1 mb-[2.5rem]">
                    <Mobile demo={demo} setDemo={setDemo} src={src} title={title} type={type} stack={stack} url={url} logo={logo} github={github}/>
                </div>
                <div className="hidden! md:flex! row  px-0 w-100 justify-between">
                    <div className="col-8 col-xl-6 px-0  h-[500px] xl:h-[625px] overflow-y-auto">
                        <iframe src={url} className="w-100 h-100"/>
                    </div>
                    <div className="col-4 col-xl-3 px-0">
                        <div className="flex flex-column items-center px-0 mb-[3rem] ">
                            <h3 className="fs-2 projectTitle text-center text-[22px]! xl:text-[38px]! text-black">
                                <img src={logo} alt=""  className="w-[80px] md:w-30! mb-2 mx-auto"/>
                                {title}
                            </h3>
                            <span className="d-flex align-items-center ms-2 mt-3">
                                <p className="p-2 rounded border text-center fs-8  xl:text-[25px]!">{type}</p>
                            </span>
                        </div>
                        <div className="mb-[3.5rem] px-0">
                            <a href={github} className="d-flex items-center onHover justify-content-center mt-4 bg-white border-0" >
                                <img src={icons.GitHub} className="w-[50px] xl:w-[70px]"/>
                                <h3 className="bg-2 text-white text-[1.25rem]! xl:text-[1.75rem]! mt-2 ms-3 p-2 xl:p-3! xl:pb-2.5!  pb-0 h-fit rounded">GitHub</h3>
                            </a>
                            <a href={url} className="d-flex items-center onHover justify-content-center mt-4 bg-white border-0" >
                                <img src={icons.Website_Icon} className="w-[50px] xl:w-[70px]"/>
                                <h3 className="bg-1 text-black mt-1 ms-3 p-2 pb-0 xl:p-3! xl:pb-2.5! h-fit text-[1.25rem]! xl:text-[1.75rem]! rounded">Website</h3>
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-wrap justify-content-center align-items-center px-0 max-[376px]:mt-1! xl:my-[1rem]! max-[376px]:pt-1! mt-3 md:pt-0! min-h-[9rem]! md:mt-0! pt-3 col-12 col-xl-3 fa-beat delay-2" style={{height: 'fit-content'}}>
                        {stack && stack.map( tech => (
                            <img key={tech} src={icons[tech]} className="max-[376px]:w-[60px]! md:w-[100px]! p-2 icons-sm  icons-md icons-lg rounded text-center" style={{height: 'fit-content'}} alt={tech}/>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Project;