import { useState } from "react";
import icons from "../../../assets/icons";

const About = () => {
    const [skills, setSkills] = useState("frontend")

    return (
        <div className="w-100 flex flex-column items-center justify-center mb-4">
            <h3 className="flex items-center justify-center max-[376px]:mb-1! max-[376px]:pt-3! mb-4 mb-xl-5 border-t-2 border-[#fcdfa6] fs-lg-heading fs-xl-heading  pt-[2rem] md:pt-6! mt-0 max-[376px]:text-[20px]! w-100 md:border-0 " ><p className="me-3 ms-xl-4 p-2 px-3   headingDecorationOne fs-4 fs-lg-heading fs-xl-heading rounded">{`<>`}</p>ABOUT <p className="p-2  headingDecorationOne fs-4 fs-lg-heading  fs-xl-heading rounded ms-3 ms-xl-4">{`</>`}</p></h3>
            <div className="row md:h-[80vh]! justify-center w-100">
                <div className="col-12 row mb-3 md:mb-0! h-fit! flex flex-column xl:justify-center md:flex-row! items-center md:items-start md:mt-[1rem]! xl:mt-0!">
                    <img src={icons.AboutBanner} alt=""  className=" col-md-6 max-[376px]:w-[5rem]! w-[10rem]! mb-2 md:mb-0 md:w-[20rem]! mx-auto md:mx-0!" />
                    <p className="fira-sans-thin-italic col-md-6 max-[376px]:text-[12px] xl:text-[30px]! xl:leading-[4rem]! aboutBio">
                        Waddup! I'm Brandon, a <b>Full Stack Web Developer</b> , that lives & breathes tech & code. I specialize in <b>React.Js</b> & <b>Vue.Js</b>  <b>Front-End technologies</b> and <b>Frameworks</b>, in combination with <b>Express.Js</b> & <b>Node.Js</b> as the <b>Backend</b>.
                    </p>
                </div>
                <div className="col-12 md:hidden md:mx-auto flex justify-center pt-2 my-md-2">
                    <div className="flex flex-column text-center">
                        <h6 className="p-2  pb-1 rounded bg-1 mb-2 max-[376px]:text-[12px]!">FRONTEND</h6>
                        {['React.Js', 'React Native', 'Vue.Js', 'Javascript', 'HTML5', 'CSS', 'Boostrap', 'TailwindCSS', 'Figma'].map( tech => (
                            <b key={tech} className="fira-sans-thin-italic-bold max-[376px]:text-[12px]!">{tech}</b>
                        ))}
                    </div>
                    <div className="mx-3 md:mx-[1.5em]! flex flex-column text-center">
                        <h6 className="p-2  pb-1 rounded bg-2 mb-2 max-[376px]:text-[12px]!">BACKEND</h6>
                        {['Node.Js', 'Express.Js', 'MongoDB', 'Javascript', 'SQL', 'Sequelize', 'Appwrite'].map( tech => (
                            <b key={tech} className="fira-sans-thin-italic-bold max-[376px]:text-[12px]!">{tech}</b>
                        ))}
                    </div>
                    <div className="d-flex flex-column text-center">
                        <h6 className="p-2  pb-1 rounded bg-3 mb-2 max-[376px]:text-[12px]!">UTILITIES</h6>
                        {['Git', 'VS Code', 'npm', 'Expo'].map( tech => (
                            <b key={tech} className="fira-sans-thin-italic-bold max-[376px]:text-[12px]!">{tech}</b>
                        ))}
                    </div>
                </div>
                <div className="col-12 flex hidden md:flex xl:justify-center h-fit!">
                        <nav className="md:ms-[3.5rem]">
                            <ul className="flex items-center flex-column">
                                <h4 className="font-[Modak] border-b-3 border-[#fcdfa6] mb-3 pb-2 md:text-center md:w-[6rem]!">SKILLS</h4>
                                <button onClick={() => setSkills("frontend")} className="p-3 hover:scale-[1.05] w-[7rem] rounded bg-1 fira-sans-semibold">FRONTEND</button>
                                <button onClick={() => setSkills("backend")} className="p-3 hover:scale-[1.05] my-4 w-[7rem] rounded bg-2 fira-sans-semibold">BACKEND</button>
                                <button onClick={() => setSkills("utilities")} className="p-3 hover:scale-[1.05] w-[7rem] rounded bg-3 fira-sans-semibold">UTILITIES</button>
                            </ul>
                        </nav>
                        <div className={`flex flex-wrap ms-8! h-[80%]! ${skills == 'frontend' ? null:'hidden'}`}>
                            {[
                                {tech: 'ReactJs', title: 'React'}, 
                                {tech: 'VueJS', title: 'Vue'}, 
                                {tech: 'HTML5', title: 'HTML'}, 
                                {tech: 'ReactNative', title: 'React Native'}, 
                                {tech: 'CSS', title: 'CSS'}, 
                                {tech: 'Bootstrap', title: 'Bootstrap'},
                                {tech: 'TailWindCSS', title: 'Tail Wind CSS'},
                                {tech: 'Javascript', title: 'Javascript'},
                                {tech: "DotNet", title: ".NET"},
                            ].map( tech => (
                                <div className="flex flex-column items-center h-[8rem] mx-2">
                                    <img key={tech.tech} src={icons[tech.tech]} className="p-2 icons-sm w-[100px]!  icons-md icons-lg rounded text-center" style={{height: 'fit-content'}} alt={tech.tech}/>
                                    <h5 className="mt-auto fira-sans-semibold text-black">{tech.title}</h5>
                                </div>
                            ))}
                        </div>
                        <div className={`flex flex-wrap ms-9! h-[80%]! ${skills == 'backend' ? null:'hidden'}`}>
                            {[{tech: 'Node', title: 'Node JS'}, {tech: "DotNet", title: ".NET"}, {tech: 'ExpressJs', title: 'Express'}, {tech: "Javascript", title: "JavaScript"}, {tech: "SequelizeJs", title: "Sequelize"}, {tech: "CSharp", title: "C#"}, {tech: "MongoDB", title:"MongoDB"}].map( tech => (
                                <div className="flex flex-column items-center h-[8rem]">
                                    <img key={tech.tech} src={icons[tech.tech]} className="p-2 icons-sm w-[100px]! icons-md icons-lg rounded text-center" style={{height: 'fit-content'}} alt={tech.tech}/>
                                    <h5 className="mt-auto fira-sans-semibold text-black">{tech.title}</h5>
                                </div>
                            ))}
                        </div>
                        <div className={`flex flex-wrap ms-8! h-[80%]! ${skills == 'utilities' ? null:'hidden'}`}>
                            {[{tech: 'npm', title: 'NPM'}, {tech: 'Expo', title: 'Expo'}, {tech: 'Appwrite', title: 'Appwrite'}, {tech: 'GitHub', title: 'GitHub'}, {tech: "Git", title: "Git"}].map( tech => (
                                <div className="flex flex-column items-center h-[8rem]">
                                    <img key={tech.tech} src={icons[tech.tech]} className="p-2 icons-sm w-[100px]!  icons-md icons-lg rounded text-center" style={{height: 'fit-content'}} alt={tech.tech}/>
                                    <h5 className="mt-auto fira-sans-semibold text-black">{tech.title}</h5>
                                </div>
                            ))}
                        </div>
                </div>
                <div className="col-12 col-md-6 mx-auto md:mx-0! max-[376px]:mb-2 h-fit! pt-2 xl:mb-5">
                    <h5 className="border-b-2 border-[#fcdfa6] pb-1 mb-3 mb-md-2 max-[376px]:pt-0! pt-2 mb-xl-4 w-25 mx-auto  text-center max-[376px]:text-[15px]! xl:text-[25px]!">More</h5>
                    <div className="d-flex align-items-center justify-content-center">
                        <button className="flex items-center justify-center onHover rounded bg-2 mx-2 py-1 px-[1.5rem] py-xl-2 px-xl-4 fa-bounce delay-1" >
                            <a href="https://pdfupload.io/docs/f24f3572" className="flex text-black items-center fira-sans-semibold fs-xl " style={{textDecoration: 'none'}}> 
                            Resume 
                            <img src={icons.ResumeIcon} alt=""  className="w-10 mx-auto aboutMoreIcons" />
                            </a>
                        </button>
                        <button className="d-flex align-items-center justify-content-center onHover rounded bg-3 mx-2 py-1 px-[1.5rem] py-xl-2 px-xl-4 fa-bounce delay-2 " >
                            <a href="https://www.linkedin.com/in/brandon-durand-15950a301/" className="flex text-black items-center  text-black fira-sans-semibold fs-xl" style={{textDecoration: 'none' }}> 
                            LinkedIn 
                            <img src={icons.LinkedInIcon} alt=""  className="w-10 mx-auto aboutMoreIcons"  />
                            </a>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;