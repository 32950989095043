import IndexStyle from '../assets/Brackets-Image.png'
import DevGreeting from '../assets/Index-Introduction.png'
import Profile from '../assets/Profile.jpg'
import IntroMessage from '../assets/Index-Intro-Name.png'
import Bracket from '../assets/Bracket.png'
import AboutBanner from '../assets/About-Banner.png'
import FaceBookIcon from '../assets/square-facebook-brands-solid.png'
import Website_Icon from '../assets/Website_Icon.png'
import TwitterIcon from '../assets/square-twitter-brands-solid.png'
import Demo_Icon from '../assets/Demo_Icon.png'
import SocialsIcon from '../assets/Socials-Icon.png'
import LinkedInIcon from '../assets/LinkedIn-Button.png'
import ResumeIcon from '../assets/Resume-Button.png'
import Javascript from '../assets/Javascript.png'
import ReactJs from '../assets/React.png'
import CSS from '../assets/CSS.png'
import VueJS from '../assets/Vue.png'
import HTML5 from '../assets/HTML5.png'
import Node from '../assets/Node.png'
import ReactNative from '../assets/ReactNative.png'
import TailWindCSS from '../assets/TailWindCSS.png'
import Expo from '../assets/Expo.png'
import Appwrite from '../assets/Appwrite.png'
import Bootstrap from '../assets/Bootstrap.png'
import npm from '../assets/Npm.png'
import SequelizeJs from '../assets/SequelizeJs.png'
import MongoDB from '../assets/MongoDb.png'
import DotNet from '../assets/DotNet.png'
import Git from '../assets/Git.png'
import ExpressJs from '../assets/ExpressJs.png'
import SelectProjectsIcon from '../assets/SelectProjectsIcon.png'
import logo from '../assets/Logo.png'
import Certificate_Icon from '../assets/Certificate_Icon.png'
import GitHub from '../assets/GitHub.png'
import CSharp from '../assets/CSharp.png'
import Web_Development_Certificate from '../assets/Web_Development_Certificate.jpg'
import Foundations_of_Coding_Front_End from '../assets/Foundations_of_Coding_Front_End.jpg'

export default {
    IndexStyle,
    DevGreeting,
    Profile,
    IntroMessage,
    SelectProjectsIcon,
    Bracket,
    AboutBanner,
    FaceBookIcon,
    TwitterIcon,
    SocialsIcon,
    LinkedInIcon,
    ResumeIcon,
    Javascript,
    ReactJs,
    CSS,
    VueJS,
    HTML5,
    Node,
    Bootstrap,
    ReactNative,
    TailWindCSS,
    Appwrite,
    Expo,
    npm,
    SequelizeJs,
    ExpressJs,
    logo,
    GitHub,
    Certificate_Icon,
    Web_Development_Certificate,
    Foundations_of_Coding_Front_End,
    Website_Icon,
    Demo_Icon,
    CSharp,
    MongoDB,
    Git,
    DotNet
}