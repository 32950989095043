import icons from "../../../assets/icons";

const Mobile = ({demo, setDemo, src, title, type, stack, url, logo, github}) => {
    return (
    <>
        <div className="col-12 col-md-6 px-0 row mx-auto mx-md-0 mt-md-2 text-center items-center fa-beat delay-1">
        {
        demo  ? 
        <>
            <div className="col-12 md:w-[400px]! h-[450px] overflow-y-auto">
                <iframe src={url} className="w-100 h-100"/>
            </div>
            <div className="flex items-center justify-center mt-4 mx-auto border-b-2 pb-3 mb-4">
                <img src={icons.Demo_Icon} alt="" className="w-[50px]! mr-4 pb-2" />
                <button className="flex items-center hover:scale-[1.01] w-auto fira-sans-semibold text-[20px]! p-1 pb-[7px]! px-2 bg-4 rounded " onClick={() => setDemo(false) }>
                    <h3 className="text-black text-[15px]! pt-[8px]">
                        {demo ? 'Info':'Website Demo'}
                    </h3>
                </button>
            </div>
        </>
        :
        <>
            <div className="flex flex-column col-6 items-center px-0 ">
                <h3 className="fs-2 projectTitle  text-[22px]! text-black">
                    <img src={logo} alt=""  className="w-[80px] max-[376px]:w-[60px]! md:w-30! mb-2 mx-auto"/>
                    {title}
                </h3>
                <span className="d-flex align-items-center ms-2 mt-3">
                    <p className="p-2 rounded border text-center max-[376px]:text-[12px] fs-8 fs-lg-sub-heading fs-xl">{type}</p>
                </span>
            </div>
            <div className="col-6 mb-[3.5rem] px-0">
                <a href={github} className="d-flex items-center onHover justify-content-center mt-4 bg-white border-0" >
                    <img src={icons.GitHub} className="max-[376px]:w-[40px] w-[50px]"/>
                    <h3 className="bg-2 text-white max-[376px]:text-[1rem]! text-[1.25rem]! mt-2 ms-3  p-2 h-fit rounded">GitHub</h3>
                </a>
                <a href={url} className="d-flex items-center onHover justify-content-center mt-4 bg-white border-0" >
                    <img src={icons.Website_Icon} className="max-[376px]:w-[40px] w-[50px]"/>
                    <h3 className="bg-1 text-black mt-1 ms-3 p-2  h-fit max-[376px]:text-[1rem]! text-[1.25rem]! rounded">Website</h3>
                </a>
            </div>
            <div className="flex items-center justify-center max-[376px]:mt-1! max-[376px]:pb-2! mt-4 mx-auto border-b-2 pb-4">
                <img src={icons.Demo_Icon} alt="" className="w-[50px]! mr-4 pb-2" />
                <button className="flex items-center hover:scale-[1.01] w-auto fira-sans-semibold text-[20px]! p-1 pb-[7px]! px-2 bg-4 rounded " onClick={() => setDemo(true)}>
                    <h3 className="text-black text-[18px]! pt-[8px]">
                        {demo ? 'Info':'Website Demo'}
                    </h3>
                </button>
            </div>
        </>
        }
    </div>
    {demo ? 
    null
    :
    <div className="flex flex-wrap justify-content-center align-items-center px-0 max-[376px]:mt-1! max-[376px]:pt-1!   mt-3 pt-3 col-12 col-md-6 fa-beat delay-2" style={{height: 'fit-content'}}>
        {stack && stack.map( tech => (
            <img key={tech} src={icons[tech]} className="max-[376px]:w-[60px]! p-2 icons-sm  icons-md icons-lg rounded text-center" style={{height: 'fit-content'}} alt={tech}/>
        ))}
    </div>
    }
    </>
    )
}

export default  Mobile;