import Certification from "./Certification";
import icons from "../../../assets/icons";
import { useState } from "react";


const Certifications = () => {
    const [certifications, setCertifications ] = useState([
        {logo: 'https://brunonavarrete.github.io/assets/img/treehouse.png', certificate: icons.Web_Development_Certificate, title:'Web Development Techdegree', link: "https://www.credential.net/fda338f3-8b97-4e52-a543-5896cfb36bac#acc.AwTyJel3"},
        {logo: 'https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://coursera.s3.amazonaws.com/media/coursera-logo-square.png?auto=format%2Ccompress&dpr=1', certificate: icons.Foundations_of_Coding_Front_End, title: 'Foundations of Coding Front-End', link: "https://www.coursera.org/account/accomplishments/certificate/A0LCIQ3FEO0L"}
    ]);
    const [currentCertificate, setCurrentCertificate] = useState(certifications[0])

    return (
        <>
            <span className="d-flex border-t-2 border-[#fcdfa6] max-[376px]:pt-[1rem]! pt-[2rem] mb-[2rem] max-[376px]:mb-5 border-md-0  justify-content-center">
                <h3 className="d-flex align-items-center max-[376px]:text-[20px]!  " style={{width: 'fit-content'}}><p className="me-3 me-xl-4 p-2 px-3  headingDecorationOne fs-4 fs-lg-heading fs-xl-heading rounded">{`<>`}</p>CERTIFICATIONS <p className="p-2 fs-lg-heading fs-xl-heading  headingDecorationOne fs-4 fs-md-6 rounded ms-3 ms-xl-4">{`</>`}</p></h3>
            </span>
            <Certification key={currentCertificate.logo} logo={currentCertificate.logo} certificate={currentCertificate.certificate} title={currentCertificate.title} link={currentCertificate.link}/>
            <nav className='flex items-center justify-center mb-5 max-[376px]:mt-[1.5rem] mt-[3.5rem] flex-column flex-md-row ps-4'>
                <div className="d-flex align-items-center w-55 justify-content-center justify-content-md-end border-md-end w-25 border-bottom border-md-bottom pe-md-4 me-md-4 pb-3 pb-md-0 mb-3 mb-md-0 pe-xl-5 me-xl-5 fa-bounce delay-1">
                    <img src={icons.Certificate_Icon} alt=""  className="me-2 projectNavIcon w-[2.5rem] md:w-[4rem]"/>
                    <h6 className="fira-sans-thin-italic-bold mb-0 ml-3 w-fit!">Select A Certificate</h6>
                </div>
                <ul className='d-flex align-items-center flex-wrap justify-content-evenly justify-content-md-start w-75 mx-auto'>
                    {certifications.map( cert => (
                        <button key={cert.logo} className={`${currentCertificate?.title === cert?.title ? 'onFocus':null} max-[376px]:w-[2.5rem]! md:w-[4rem]! xl:w-[5.5rem]! border p-1 rounded justify-content-center hover:scale-[1.05] bg-white m-1 mx-xl-2 mb-2 btn-sm btn-md btn-lg btn-xl fa-flip`} onClick={() => setCurrentCertificate(cert)}><img src={cert.logo} alt="" className="w-100" /></button>
                    ))}
                </ul>
            </nav>
        </>
    )
}

export default Certifications;