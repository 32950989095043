import { getAllProjects } from "../../appwrite/appwrite";
import useAppwrite from "../../appwrite/useAppwrite";
import icons from "../../assets/icons";
import About from "./about/About";
import Certifications from "./certifications/Certifications";
import Projects from "./projects/Projects";

const Home = () => {
    const { data:projects } = useAppwrite(() => getAllProjects());
    return (
        <div className="w-100 h-100vh flex justify-center pt-1 px-4 row ">
            <div className="flex sm:flex-column col-12 row px-0 mb-6 pb-2 mb-md-0" id="Home">
                <div className="mx-auto col-9 col-xl-5 flex flex-column">
                    <div className="w-100 mx-auto flex">
                        <img src={icons.IntroMessage} className="col-10 col-md-12  col-xl-5 mx-auto" alt=""   />
                    </div>
                    <div className="mx-auto" style={{display: 'grid'}}>
                        <img src={icons.IndexStyle} alt="" className="mx-auto col-9 col-lg-8 col-xl-6" style={{gridRow: '1/2', gridColumn: '1/2'}}/>
                        <img src={icons.Profile} alt="" className=" rounded mx-auto col-4 col-lg-4  col-xl-3 my-auto" style={{gridRow: '1/2', gridColumn: '1/2'}}/>
                    </div>
                </div>
                <div className="col-12 md:flex justify-center max-[376px]:mb-4">
                    <img src={icons.DevGreeting} alt=""  className="max-[376px]:w-[15rem]! mx-auto md:mx-0! w-[30rem]! xl:w-[40rem]!"/>
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="mx-2">
                            <div className="border-b-2 border-[#fcdfa6] text-center w-50 pb-2 mx-auto" >
                                <img src={icons.SocialsIcon}  alt=""  className="homeIconSocials max-[376px]:w-[8rem] w-[10rem] xl:w-[15rem]!" />
                            </div>
                            <div className="d-flex align-items-center w-100 mt-2 justify-content-center">
                                {['https://www.facebook.com/profile.php?id=61559539658958', 'https://x.com/BrandonDurand16'].map( social => (
                                    <button key={social} className="bg-white border-0 mx-1 iconHover iconSocials fa-bounce max-[376px]:w-[2rem] w-[3rem] xl:w-[4rem]!"><a href={social}><img src={social.includes('facebook') ? icons.FaceBookIcon:icons.TwitterIcon} alt="" className="w-100" /></a></button>
                                ))}
                            </div>
                        </div>
                        <a href="https://play.google.com/store/apps/details?id=com.ptco.aora&hl=en-US&ah=hNil1abhS600pczlYjOJYRHu4SM&pli=1" className="d-flex flex-column mx-2 flex-md-row align-items-center justify-content-end mobileAppIconHome">
                            <h5 className="d-flex align-items-center me-0 me-md-3 mb-3 mb-md-0 text-color-2"><b className="mb-0 me-1 text-color-1">Try</b> Mobile <b className="mb-0 ms-1 text-black">App</b></h5>
                            <img src={icons.logo} alt=""  className="w-100 max-[376px]:w-[5rem]! footer-lg-logo  footer-xl-logo border rounded-4"/>
                        </a>
                    </div>
                </div>
            </div>
            <div className="col-12 flex flex-column px-0 " id="About">
                <About />
            </div>
            <div className="col-12 row  flex flex-column h-100" id="Projects">
                <Projects projects={projects}/>
            </div>
            <div className="col-12 flex flex-column justify-center h-100 xl:h-[100vh]!" id="Certifications">
                <Certifications />
            </div>
        </div>
    )
}

export default Home;